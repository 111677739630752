import { Override } from "framer"

export function TruncateTextOneLine(): Override {
    return {
        style: {
            display: "-webkit-box",
            WebkitLineClamp: 1,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    }
}

export function TruncateTextTwoLines(): Override {
    return {
        style: {
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    }
}

export function TruncateTextThreeLines(): Override {
    return {
        style: {
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    }
}
